import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const queen = ({ data }) => {
  return <Layout></Layout>;
};

export const query = graphql`
  query strapiArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      Title
      id
      slug
      HeadingWebm {
        url
        width
        height
        alternativeText
      }
      ArticleShort {
        data {
          ArticleShort
        }
      }
      HeadingImage {
        alternativeText
        height
        width
        url
      }
      updatedAt
    }
  }
`;
export default queen;
